import home_page from '../../content/home_page'
import Image from '../Image/Image'
import './style.scss'

const { title, logos } = home_page.partners_section

const PartnersSection = () => {
  return (
    <div className="container py-10">
      <h3 className="PartnersLogoSection__title">{title}</h3>
      <div className="PartnersLogoSection">
        {logos.map((logo, index) => (
          <div
            key={`partner-logo-${index}`}
            className="PartnersLogoSection__logo-wrapper"
          >
            <Image
              data-testid="partner-logo"
              className="PartnerLogo"
              src={logo.src}
              alt={logo.alt}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PartnersSection
