import home_page from '../../content/home_page'
import PennyLaneVersion from '../PennyLaneVersion/PennyLaneVersion'
import LinkComponent from '../LinkComponent/LinkComponent'
import './Hero.scss'

const { title, subtitles, left_button, right_button } = home_page.hero_section

const Hero = () => {
  return (
    <section className="HeroSection">
      <div className="HeroSection__content">
        <div className="flex items-center justify-center pb-4 h-[50px]">
          <PennyLaneVersion />
        </div>
        <h1>{title}</h1>
        <div className="HeroSubtitles">
          {subtitles.map((subtitle, index) => {
            return (
              <h2 key={`subtitle-${index}`} className="HeroSubtitle">
                {subtitle.text}
                <span className="Subtitle__link">
                  <LinkComponent href={subtitle.link}>
                    {subtitle.link_text}
                  </LinkComponent>
                </span>
              </h2>
            )
          })}
        </div>
        <div className="Hero__buttons">
          <LinkComponent
            href={left_button.link}
            addClass="Button__primary--blue Hero__button"
          >
            <>
              {left_button.text}
              {left_button.icon && <i className={`bx ${left_button.icon}`}></i>}
            </>
          </LinkComponent>
          <LinkComponent
            href={right_button.link}
            addClass="Button__secondary--blue Hero__button"
          >
            <>
              {right_button.text}
              {right_button.icon && (
                <i className={`bx ${right_button.icon}`}></i>
              )}
            </>
          </LinkComponent>
        </div>
      </div>
    </section>
  )
}

export default Hero
