import LinkComponent from '../LinkComponent/LinkComponent'
import './CTALink.scss'

const CTALink = ({ label, href }: { label: string; href: string }) => {
  return (
    <LinkComponent addClass="CTALink" href={href}>
      <span data-testid="cta-link-label">{label}</span>{' '}
      <i
        data-testid="cta-link-icon"
        className="bx bx-chevron-right text-[24px]"
      ></i>
    </LinkComponent>
  )
}

export default CTALink
